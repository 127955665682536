



























































































































































































































































































import { AppModule } from '@/store/modules/app'
import { Component, Vue, Watch } from 'vue-property-decorator'
import router from '@/router'
import { Form as ElForm } from 'element-ui'
import { setMeta } from '@/utils/compatible'
import stepsBank from '../../components/stepsBank.vue'
import stepsH5 from '../../components/stepsH5.vue'
import bottom from '../../components/bottom.vue'
import vueQr from 'vue-qr'
import { getCounter, quickpayBindBank } from '@/api'
import moment from 'moment'
import {
  getIsLoading,
  setIsLoading,
  getOrderStatus,
  setOrderStatus,
  getBankList,
  setBankList
} from '@/utils/cookies'
import Cookies from 'js-cookie'

@Component({
  name: 'genaral',
  components: {
    stepsBank,
    bottom,
    stepsH5,
    vueQr
  }
})
export default class extends Vue {
  [x: string]: any
  get isQrcode() {
    const imgRegx: any = /^data:image\/png;base64,/
    return imgRegx.test(this.orderInfo.extra_param.qrcode)
  }

  get tips() {
    const extra_param = this.orderInfo.extra_param
    let str: any =
      extra_param.bank_code === 'BBL'
        ? this.$t('online.otp_scb_tips')
        : extra_param.code_type === 'PIN_CODE'
        ? this.$t('online.otp_pin_tips')
        : this.$t('online.otp_other_tips')
    str += ' ' + this.orderInfo.extra_param.ref
    return str
  }
  get isShowStepTitle() {
    return window.screen.availWidth > 768
  }
  public loading: boolean = false
  public btLoading: boolean = false
  public orderInfo: any = {
    bank: [],
    extra_param: {},
    expire_time: null
  }
  public pollCount = 0 // 轮询次数
  public poll: any = null // 绑卡后 轮询订单状态
  public stepOps: any[] = []
  public action: number = 0
  public bankList: any[] = []

  get logo() {
    try {
      return require(`@/assets/bank-logo/quickpay_bank/${Cookies.get(
        'currency'
      )}/${this.orderInfo.extra_param.bank_code}.png`)
    } catch (e) {
      return this.orderInfo.bank.filter(
        (item: any) => item.id === this.orderInfo.extra_param.paybank
      )[0]?.logo
    }
  }
  public validateUsername = (rule: any, value: string, callback: Function) => {
    if (!value.trim()) {
      this.loginForm.username = value.trim()
      callback(new Error('Please enter the correct user name'))
    } else {
      callback()
    }
  }
  public validatePassword = (rule: any, value: string, callback: Function) => {
    this.loginForm.password = value.trim()
    if (this.loginForm.password.length < 6) {
      callback(new Error('The password can not be less than 6 digits'))
    } else {
      callback()
    }
  }
  public validateOtp = (rule: any, value: string, callback: Function) => {
    if (!value.trim()) {
      this.otpForm.otp = value.trim()
      callback(new Error('Please input the verification code'))
    } else {
      callback()
    }
  }
  public validateGridCardForm = (
    rule: any,
    value: string[],
    callback: Function
  ) => {
    if (value.length > 0) {
      const hasNull = value.some((item: string) => {
        return !item
      })
      if (hasNull) {
        callback(new Error('Please enter in full your Debit Grid'))
      } else {
        callback()
      }
    } else {
      callback()
    }
  }
  public loginForm = {
    username: '',
    password: ''
  }
  public publicOtp: any = ''
  public otpForm = {
    otp: '',
    bank_value: '',
    public_otp: '',
    gridCardForm: []
  }
  public loginRules = {
    username: [{ validator: this.validateUsername, trigger: 'blur' }],
    password: [{ validator: this.validatePassword, trigger: 'blur' }]
  }
  public otpRules = {
    otp: [{ validator: this.validateOtp, trigger: 'blur' }],
    bank_value: [
      { required: true, message: 'Please select bank', trigger: 'blur' }
    ],
    gridCardForm: [
      {
        validator: this.validateGridCardForm,
        trigger: 'blur',
        type: 'array'
      }
    ]
  }
  public capsTooltip = false
  public isResetTime = true
  public time = 0
  public timer: any = null
  public checkCapslock(e: KeyboardEvent) {
    const { key } = e
    this.capsTooltip =
      key !== undefined &&
      key !== null &&
      key.length === 1 &&
      key >= 'A' &&
      key <= 'Z'
  }

  @Watch('orderInfo.extra_param.thirdparty_status')
  public onChanege() {
    this.isResetTime = true
  }

  public handleLogin() {
    ;(this.$refs.loginForm as ElForm).validate(async (valid: boolean) => {
      if (valid) {
        this.btLoading = true
        try {
          const data: any = {
            login_account: this.loginForm.username,
            login_password: this.loginForm.password
          }
          await quickpayBindBank(data).then(() => {
            setIsLoading('loading')
            if (this.timer) {
              clearInterval(this.timer)
              this.timer = null
              this.isResetTime = true
            }
          })
        } catch (e) {
          this.loginForm.username = ''
          this.loginForm.password = ''
          this.btLoading = false
        }
      }
    })
  }

  public async bind() {
    ;(this.$refs.otpForm as ElForm).validate(async (valid: boolean) => {
      if (valid) {
        try {
          this.loading = true
          this.btLoading = true
          const data: any = this.setData()
          await quickpayBindBank(data).then(() => {
            setIsLoading('loading')
            setBankList(JSON.stringify([]))
            if (this.timer) {
              clearInterval(this.timer)
              this.timer = null
              this.isResetTime = true
            }
          })
        } catch (e) {
          this.loading = false
          this.btLoading = false
        } finally {
          this.otpForm.otp = ''
          this.otpForm.gridCardForm = []
        }
      } else {
        return false
      }
    })
  }
  public setData() {
    const status: any = getOrderStatus()
    let publicOtp = ''
    this.otpForm.gridCardForm.map((val, index) => {
      if (index === 0) {
        publicOtp = publicOtp + '' + val
      } else if (index === this.otpForm.gridCardForm.length) {
        publicOtp = publicOtp + val + ''
      } else {
        publicOtp = publicOtp + '###' + val
      }
    })
    this.otpForm.public_otp = publicOtp // 输入的银行卡背后的值

    if (status === 'bank_list' && this.bankList.length > 0) {
      return { bank_value: this.otpForm.bank_value }
    }

    if (
      status === ('public_otp_retry' || status === 'processing') &&
      this.publicName === 'grid_card'
    ) {
      return {
        public_otp: this.otpForm.public_otp,
        public_name: this.orderInfo.extra_param.public_name
      }
    }
    if (status === 'public_otp_retry' || status === 'processing') {
      return {
        public_otp: this.otpForm.otp,
        public_name: this.orderInfo.extra_param.public_name
      }
    }
    if (
      status === 'public_otp_retry' ||
      (status === 'public_otp' && this.publicName === 'login')
    ) {
      return {
        public_otp: this.otpForm.otp,
        public_name: this.orderInfo.extra_param.public_name
      }
    }
    if (status === 'public_otp_retry' || status === 'public_otp') {
      return {
        public_otp: this.otpForm.public_otp,
        public_name: this.orderInfo.extra_param.public_name
      }
    }
    if (status === '') {
      return { otp: this.otpForm.otp }
    }
  }

  // 开启轮询
  public startPoll() {
    if (!this.poll) {
      this.poll = setInterval(() => {
        this.getCounter()
      }, 3000)
    }
  }

  // 开启倒计时
  public startTime() {
    if (!this.timer) {
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        if (--this.time <= 0) {
          try {
            clearInterval(this.timer)
            this.timer = null
          } catch (e) {}
        }
      }, 1000)
    }
  }
  public gridCardList: any = []
  public publicName: any = ''
  public async getCounter() {
    try {
      await getCounter().then((res: any) => {
        this.orderInfo = res
        this.gridCardList = res.extra_param.public_params.grid_card_list
        this.publicName = res.extra_param.public_name
        if (
          this.gridCardList &&
          this.gridCardList.length > 0 &&
          this.otpForm.gridCardForm.length === 0
        ) {
          this.$set(
            this.otpForm,
            'gridCardForm',
            this.gridCardList.map((item: string) => {
              return ''
            })
          )
        }
        if (res.status === 0 && res.is_expired) {
          // 订单未支付 且订单过期
          if (this.poll) {
            clearInterval(this.poll)
            this.poll = null
          }
          if (this.timer) {
            clearInterval(this.timer)
            this.timer = null
          }
          router.replace({ name: 'error' })
        } else if (res.status === 1) {
          // 订单已支付。跳转成功页
          if (this.poll) {
            clearInterval(this.poll)
          }
          if (this.timer) {
            clearInterval(this.timer)
          }
          router.replace({ name: 'success' })
        } else {
          // 订单未支付 且订单未过期
          // 设置倒计时
          if (this.isResetTime) {
            this.time =
              this.orderInfo.expire_time -
              Number(moment(new Date()).format('X'))
            this.isResetTime = false
          }
          if (this.pollCount === 0) {
            // 初始化
            if (res.extra_param.paybank) {
              // 已绑卡
              // 开启轮询
              this.startPoll()
            }
            this.pollCount++
          }
          // 已登录成功，跳往验证码页
          if (res.extra_param.login_status === 1) {
            this.action = 1
          }
          // 正登录或已登录过。等待跳转到填写验证码
          if (
            !!res.extra_param.login_account &&
            res.extra_param.login_status === 0
          ) {
            this.loading = true
          }
          // this.action = 1
          this.options(res)
        }
      })
    } catch (e) {
      if (this.poll) {
        clearInterval(this.poll)
      }
      this.loading = false
    }
  }

  public options(res) {
    const thirdparty_status = res.extra_param.thirdparty_status
    switch (thirdparty_status) {
      case 'processing':
        setIsLoading('')
        setOrderStatus('')
        this.loading = false
        this.btLoading = false
        this.action = 1
        // 开启倒计时
        this.startTime()
        setBankList(JSON.stringify([]))
        this.bankList = []
        this.quickpayBindBank({ reset: 1 })
        break
      case 'failed':
        // failed：操作错误，跳回登录页
        setIsLoading('')
        setOrderStatus('')
        this.loading = false
        this.btLoading = false
        setBankList(JSON.stringify([]))
        this.bankList = []
        this.quickpayBindBank({ reset: 1 })
        if (res.extra_param.error_msg) {
          this.$alert(res.extra_param.error_msg, {
            confirmButtonText: 'confirm',
            callback: action => {
              this.action = 0
              this.isResetTime = true
              this.startTime()
            }
          })
        }
        break
      case 'retry':
        // retry： 重新填写验证码；
        setIsLoading('')
        setOrderStatus('')
        this.btLoading = false
        this.loading = false
        this.quickpayBindBank({ reset: 1 })
        if (res.extra_param.error_msg) {
          this.$alert(res.extra_param.error_msg, {
            confirmButtonText: 'confirm',
            callback: action => {
              this.action = 1
            }
          })
        }
        setBankList(JSON.stringify([]))
        this.bankList = []
        this.startTime()
        break
      case 'bank_list':
        setIsLoading('')
        this.loading = false
        this.btLoading = false
        this.action = 1
        setOrderStatus(thirdparty_status)
        this.quickpayBindBank({ reset: 1 })
        // 开启倒计时
        this.startTime()
        setBankList(JSON.stringify(res.extra_param.bank_list))
        this.bankList = res.extra_param.bank_list
        break
      case 'public_otp':
        setIsLoading('')
        this.loading = false
        this.btLoading = false
        setOrderStatus(thirdparty_status)
        this.quickpayBindBank({ reset: 1 })
        // 开启倒计时
        this.startTime()
        setBankList(JSON.stringify([]))
        this.bankList = []
        break
      case 'public_otp_retry':
        setIsLoading('')
        setOrderStatus(thirdparty_status)
        this.loading = false
        this.btLoading = false
        setOrderStatus(thirdparty_status)
        this.quickpayBindBank({ reset: 1 })
        if (res.extra_param.error_msg) {
          this.$alert(res.extra_param.error_msg, {
            confirmButtonText: 'confirm',
            callback: action => {
              this.action = 1
            }
          })
        }
        // 开启倒计时
        this.startTime()
        setBankList(JSON.stringify([]))
        this.bankList = []
        break
      default:
        break
    }
  }
  public async quickpayBindBank(data: any) {
    await quickpayBindBank(data).then(() => {})
  }

  created() {
    if (
      Cookies.get('currency') === 'THB' ||
      sessionStorage.getItem('currency') === 'THB'
    ) {
      AppModule.SetLanguage('th')
    }
    setMeta()
    AppModule.SetNavbar(false)
  }

  mounted() {
    this.loading = getIsLoading() === 'loading'
    this.bankList = JSON.parse(getBankList() || '[]')
    this.action = 0
    this.pollCount = 0
    this.stepOps = [
      this.$t('online.stepsWeb.step1'),
      this.$t('online.stepsWeb.step2'),
      this.$t('online.stepsWeb.step3')
    ]
    this.getCounter()
    this.startTime()
  }

  destroyed() {
    if (this.poll) {
      clearInterval(this.poll)
    }
    if (this.timer) {
      clearInterval(this.timer)
    }
  }
}
